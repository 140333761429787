import { routes } from "@/pages"
import { FC, memo, Suspense, useEffect } from "react"
import { HelmetProvider } from "react-helmet-async"
import { createBrowserRouter, RouterProvider } from "react-router-dom"
import "./index.less"

import { checkAuth } from "@/shared/api"
import { useAuthStore } from "@/store/auth"
import { useConnectorsStore } from "@/store/connectors"
import { getAuthToken } from "@/utils/auth"

const App: FC = () => {
  const userLoggedIn = useAuthStore(store => store.loggedIn)
  const setStore = useAuthStore(store => store.set)
  const setConnectors = useConnectorsStore(state => state.setConnectors)
  const router = createBrowserRouter(routes(userLoggedIn))

  const initialAuth = async () => {
    try {
      let response: any = await checkAuth(getAuthToken())
      response = await response.json()
      setStore(store => {
        store.loggedIn = !!response
        store.user = response.user ?? response
      })
      setConnectors(response.connectors)
    } catch (error) {
      setStore(store => {
        store.loggedIn = false
        store.user = undefined
      })
      console.error("Error:", error)
    }
  }

  useEffect(() => {
    initialAuth()
  }, [])

  return (
    <HelmetProvider>
      <Suspense>
        <RouterProvider router={router} />
      </Suspense>
    </HelmetProvider>
  )
}

export default memo(App)

export { App }
