import { Layout } from "antd"
import { FC, useState } from "react"
import { Outlet } from "react-router-dom"
import { useTernaryDarkMode } from "usehooks-ts"
import "./DefaultLayout.less"

const DefaultLayout: FC = () => {
  const { isDarkMode } = useTernaryDarkMode({ localStorageKey: "theme" })
  return (
    <Layout className={isDarkMode ? "dark" : "light"}>
      <Outlet />
    </Layout>
  )
}

export default DefaultLayout
