export function getAuthToken(): string | null {
  return localStorage.getItem("token")
}

export function setAuthToken(token: string | null): void {
  if (!token) {
    localStorage.removeItem("token")
  } else {
    localStorage.setItem("token", token)
  }
}
