import { useAuthStore } from "@/store/auth"
import { PublicConnectorDetails } from "@/store/newPublicConnector"
import { AnalyticsResponseType, TransactionsAnalyticsResponseData } from "@/types/Analytics"
import { ConfigType, ConnectorType, DataConnectorsType } from "@/types/DataConnectorsType"
import { ResponseType } from "@/types/LoginResponseType"
import { StatisticType } from "@/types/StatisticType"
import { TemplatesType } from "@/types/TemplatesType"
import { UserType } from "@/types/UserType"
import ky from "ky"

const API_URL = `${import.meta.env.WEBSITE_API_BASE_URL ?? "https://datacord.bndigital.dev"}/api/`

const api = ky.create({
  prefixUrl: API_URL,
  hooks: {
    beforeRequest: [
      async request => {
        const token = localStorage.getItem("token")!
        if (token) {
          request.headers.set("Content-Type", "application/json")
          request.headers.set("Authorization", `Bearer ${token}`)
        }
      },
    ],
    afterResponse: [
      async (request, options, response) => {
        if (response.status === 401 || response.status === 500) {
          const refresh = useAuthStore.getState().refresh
          await refresh()

          const newToken = localStorage.getItem("token")!
          if (newToken) {
            request.headers.set("Content-Type", "application/json")
            request.headers.set("Authorization", `Bearer ${newToken}`)
            return ky(request)
          }
        }
        return response
      },
    ],
  },
})

const AUTH_PATH = "auth"
const ME_PATH = "users/me"
const ALL_USERS_PATH = "users"
const CONFIRM_USER = "users/confirm"

const REGISTRATION_PATH = "registration"
const REFRESH_PATH = "refresh"
const RESET_PASSWORD_PATH = "users/reset-password"
const UPDATE_PASSWORD_PATH = "users/update-password"

const ALL_CONNECTORS_PATH = "connectors"
const CREATE_CONNECTOR_PATH = "connectors/"
const UPDATE_CONNECTOR_PATH = "connectors/"
const DELETE_CONNECTOR = "connectors/"
const VALIDATE_CONFIG = "connectors/validateConfig"
const VALIDATE_DATA_MAPPING = "connectors/validateDataMapping"
const PAUSE_CONNECTOR = "connectors/pause"

const ALL_CONFIGS_PATH = "configs"

const GET_DASHBOARD_ANALYTICS = "analytics/dashboard"
const GET_STATISTIC = "analytics/statistic"
const GET_TRANSACTIONS = "analytics/transactions"

const GET_ALL_PUBLIC_CONNECTORS = "templates"
const CREATE_PUBLIC_CONNECTOR = "templates"
const UPDATE_PUBLIC_CONNECTOR = "templates/"
const DELETE_PUBLIC_CONNECTOR = "templates/"

export async function checkAuth(token: string | null) {
  return api.get(ME_PATH, {
    headers: {
      "Authorization": token ? `Bearer ${token}` : undefined,
      "Content-Type": "application/json",
    },
  })
}

export async function confirmUser(token: string): Promise<ResponseType> {
  return ky(`${API_URL}${CONFIRM_USER}`, {
    method: "POST",
    headers: {
      "Authorization": token ? `Bearer ${token}` : undefined,
      "Content-Type": "application/json",
    },
  }).json()
}

export async function login(email: string, password: string): Promise<ResponseType> {
  return ky(`${API_URL}${AUTH_PATH}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email, password }),
  }).json()
}

export async function registration(
  email: string,
  password: string,
  firstName: string,
  lastName: string,
  workspaceName?: string,
  companyName?: string
) {
  return ky(`${API_URL}${REGISTRATION_PATH}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email, password, firstName, lastName, workspaceName, companyName }),
  }).json()
}

export async function handleRefreshToken(refreshToken: string) {
  return api
    .post(REFRESH_PATH, {
      headers: {
        "Content-Type": "application/json",
      },
      json: { refreshToken },
    })
    .json()
}

export async function resetPassword(email: string) {
  return ky(`${API_URL}${RESET_PASSWORD_PATH}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email }),
  }).json()
}

export async function updatePassword(password: string, token: string) {
  return ky(`${API_URL}${UPDATE_PASSWORD_PATH}`, {
    method: "POST",
    headers: {
      "Authorization": token ? `Bearer ${token}` : undefined,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ password: { new: password } }),
  }).json()
}

export async function getAllConnectors(token: string): Promise<DataConnectorsType[]> {
  return api
    .get(ALL_CONNECTORS_PATH, {
      headers: {
        "Authorization": token ? `Bearer ${token}` : undefined,
        "Content-Type": "application/json",
      },
    })
    .json()
}

export async function getConnector(id: string, token: string): Promise<DataConnectorsType> {
  return api
    .get(`${ALL_CONNECTORS_PATH}/${id}`, {
      headers: {
        "Authorization": token ? `Bearer ${token}` : undefined,
        "Content-Type": "application/json",
      },
    })
    .json()
}

export async function createConnector(body: any, token: string): Promise<DataConnectorsType> {
  return api
    .post(CREATE_CONNECTOR_PATH, {
      headers: {
        "Authorization": token ? `Bearer ${token}` : undefined,
        "Content-Type": "application/json",
      },
      json: body,
    })
    .json()
}

export async function updateConnector(
  body: Partial<ConnectorType>,
  id: string,
  token: string
): Promise<DataConnectorsType> {
  return api
    .patch(`${UPDATE_CONNECTOR_PATH}${id}`, {
      headers: {
        "Authorization": token ? `Bearer ${token}` : undefined,
        "Content-Type": "application/json",
      },
      json: body,
    })
    .json()
}

export async function validateConfig(body: any, token: string) {
  return api
    .post(VALIDATE_CONFIG, {
      headers: {
        "Authorization": token ? `Bearer ${token}` : undefined,
        "Content-Type": "application/json",
      },
      json: { source: body },
    })
    .json()
}

export async function validateDataMapping(body: any, token: string) {
  return api
    .post(VALIDATE_DATA_MAPPING, {
      headers: {
        "Authorization": token ? `Bearer ${token}` : undefined,
        "Content-Type": "application/json",
      },
      json: { dataMapping: body },
    })
    .json()
}

export async function getAllUsers(): Promise<UserType[]> {
  return api.get(ALL_USERS_PATH).json()
}

export async function removeConnector(id: string, token: string): Promise<ConnectorType> {
  return api
    .delete(`${DELETE_CONNECTOR}${id}`, {
      headers: {
        "Authorization": token ? `Bearer ${token}` : undefined,
        "Content-Type": "application/json",
      },
    })
    .json()
}

export async function getConfigById(id: string, token: string): Promise<ConfigType> {
  return api
    .get(`${ALL_CONFIGS_PATH}/${id}`, {
      headers: {
        "Authorization": token ? `Bearer ${token}` : undefined,
        "Content-Type": "application/json",
      },
    })
    .json()
}

export async function pauseConnector(id: string, token: string): Promise<ConfigType> {
  return api
    .post(`${PAUSE_CONNECTOR}/${id}`, {
      headers: {
        "Authorization": token ? `Bearer ${token}` : undefined,
        "Content-Type": "application/json",
      },
    })
    .json()
}

export async function getDashboardAnalytics(
  connectorId: string,
  startDate: string,
  endDate: string,
  interval: string,
  token: string,
  status?: string[]
): Promise<AnalyticsResponseType> {
  return api
    .post(`${GET_DASHBOARD_ANALYTICS}`, {
      headers: {
        "Authorization": token ? `Bearer ${token}` : undefined,
        "Content-Type": "application/json",
      },
      json: { connectorId, startDate, endDate, interval, statusCodes: status },
    })
    .json()
}

export async function getStatistic(
  connectorId: string,
  startDate: string,
  endDate: string,
  token: string
): Promise<StatisticType> {
  return api
    .post(`${GET_STATISTIC}`, {
      headers: {
        "Authorization": token ? `Bearer ${token}` : undefined,
        "Content-Type": "application/json",
      },
      json: { connectorId, startDate, endDate },
    })
    .json()
}

export async function getTransactionsAnalytics(
  connectorId: string,
  startDate: string,
  endDate: string,
  token: string
): Promise<TransactionsAnalyticsResponseData> {
  return api
    .post(`${GET_TRANSACTIONS}`, {
      headers: {
        "Authorization": token ? `Bearer ${token}` : undefined,
        "Content-Type": "application/json",
      },
      json: { connectorId, startDate, endDate },
    })
    .json()
}

export async function getAllPublicConnectors(token: string): Promise<TemplatesType[]> {
  return api
    .get(GET_ALL_PUBLIC_CONNECTORS, {
      headers: {
        "Authorization": token ? `Bearer ${token}` : undefined,
        "Content-Type": "application/json",
      },
    })
    .json()
}

export async function getPublicConnectorById(id: string, token: string): Promise<PublicConnectorDetails[]> {
  return api
    .get(`${GET_ALL_PUBLIC_CONNECTORS}/${id}`, {
      headers: {
        "Authorization": token ? `Bearer ${token}` : undefined,
        "Content-Type": "application/json",
      },
    })
    .json()
}

export async function createPublicConnectors(
  body: PublicConnectorDetails,
  token: string
): Promise<PublicConnectorDetails[]> {
  return api
    .post(CREATE_PUBLIC_CONNECTOR, {
      headers: {
        "Authorization": token ? `Bearer ${token}` : undefined,
        "Content-Type": "application/json",
      },
      json: body,
    })
    .json()
}

export async function updatePublicConnector(id: string, body: any, token: string): Promise<DataConnectorsType> {
  return api
    .put(`${UPDATE_PUBLIC_CONNECTOR}${id}`, {
      headers: {
        Authorization: token ? `Bearer ${token}` : undefined,
      },
      json: body,
    })
    .json()
}
